.page {
    width: 100%;
    height:100%;
    position: absolute;
    overflow: auto;
}

.top-tags { 
    bottom: auto;
    top: 35px;
}

.tags {
    color: #ffd700;
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 18px;
    font-family: 'La Belle Aurore', cursive;
}

.bottom-tag-html { 
    margin-left: -20px;
}

.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: relative;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;

    @media screen and (max-width: 800px) {
        margin-left: 24px;
        padding: 20px;
        margin-bottom: 20px;
    }

}

.container.about-page,
.container.projects-page,
.container.contact-page {
    .text-zone {
        position: absolute;
        left: 10%; 
        top: 50%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;

        @media screen and (max-width: 800px) {
            width: 65%;
            margin-left: 16px;
        }

        h1{
            font-size: 53px;
            font-family: 'ZCOOL XiaoWei' , serif;
            color: #ffd700;
            margin:0;
            font-family: 'ZCOOL XiaoWei' , serif;
            font-weight: 400;
            margin-left: 20px;

            // &::before {
            //     content: '<h1>';
            //     font-family: 'La Belle Aurore', cursive;
            //     color: #ffd700;
            //     font-size: 18px;
            //     position: absolute;
            //     margin-top: -40px;
            //     left: 15px;
            //     opacity: 0.6;
            // }
    
            // &::after {
            //     content: '</h1>';
            //     font-family: 'La Belle Aurore', cursive;
            //     color:#ffd700;
            //     font-size: 18px;
            //     position: absolute;
            //     margin-top: 18px;
            //     margin-left: 20px;
            //     animation: fadeIn 1s 1.7s backwards;
            //     opacity: 0.6;
                
            // }
        }

        p {
            font-size: 18px;
            color: #fff;
            font-family: sans-serif;
            font-weight: 300;
            min-width: fit-content;
            animation: pulse 1s;

            // @media screen and (max-width: 800px) {
            //     margin-left: 24px;
            // }

            &:nth-of-type(1) {
                animation-delay: 1.1s;
            }

            &:nth-of-type(2) {
                animation-delay: 1.2s;
            }

            &:nth-of-type(3) {
                animation-delay: 1.3s;
            }

        }
    }       
}

