$primary-color: #ffd700;

@import 'animate.css';
@import url('https://fonts.googleapis.com/css2?family=ZCOOL+XiaoWei&display=swap');
@import url('https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap');
@import '~loaders.css/src/animations/pacman.scss';

input, 
textarea {
  font-family: 'ZCOOL XiaoWei' , serif;
}

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
}