.home-page {

    .text-zone { 
        position: absolute;
        left: 10%;
        top:50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    }

    h1 { 
        color: white;
        font-size: 32px;
        margin:0;
        font-family: 'ZCOOL XiaoWei' , serif;
        font-weight: 400;
        margin-left: 20px;

        @media screen and (min-width: 800px) {

            font-size: 53px;

            &::before {
                content: '<h1>';
                font-family: 'La Belle Aurore', cursive;
                color: #ffd700;
                font-size: 18px;
                position: absolute;
                margin-top: -40px;
                left: 15px;
                opacity: 0.6;
            }
    
            &::after {
                content: '</h1>';
                font-family: 'La Belle Aurore', cursive;
                color:#ffd700;
                font-size: 18px;
                position: absolute;
                margin-top: 18px;
                margin-left: 20px;
                animation: fadeIn 1s 1.7s backwards;
                opacity: 0.6;
                
            }
            
        }

    }
}

h2 {
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
}

.flat-button { 
    color:#ffd700;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    text-decoration: none;
    font-family: sans-serif;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    margin-top: 25px;
    float: left;
    animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;

    &:hover { 
        background: #ffd700;
        color: #333
    }
}